
#TableHeader {
    background-color: #efefef;
    text-transform: uppercase;
    text-align: center;
    font-weight: 635;
    border-bottom: 2px solid #3766c3;
    font-size:0.95em;
}

#PKPNTableBody {
    font-size: 0.9em;
    vertical-align: top;
    padding: 0.7em;
    max-width: calc(20vw);
    border: 1px solid #f3f3f3;
    word-break: break-word;
}

#pkpnTable {
    justify-content: center;
    max-height: 36em;
}

.CardOverflow {
    width: 100%;
    overflow-x: auto;
}

.BarWidget {
    position: relative;
    width: 100%;
    height: 27px;
}

    .BarWidget .visual-progress-approval {
        background-color: #17a2b8;
        height: 100%;
        float: left
    }
    .BarWidget .visual-inprogress {
        background-color: #d8aa1f;
        width: 100%;
        height: 100%;
        float: left
    }
    .BarWidget .visual-error {
        background-color: #495057;
        width: 100%;
        height: 100%;
        float: left
    }
    .BarWidget .visual-progress-count {
        color: #fff;
        float: right;
        margin-top: 10px;
        margin-right: 6px;
        font-size: 12px;
    }

.RowFlex {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 2em;
    margin-top: 2em;
    width: 95%;
}
    .RowFlex .MuiTypography-body2 {
        font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    }

    .RowFlex .MuiTableCell-root {
        font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    }
    .RowFlex .pkdd-buttonGroup .MuiButton-root {
        text-transform: none;
        font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-size: 1em;
        font-weight: 400;
    }
    .RowFlex .commentBox .MuiButton-root {
        text-transform: none;
        font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-size: 1em;
        font-weight: 400;
    }

    .RowFlex .TopTable .refreshTopTable .MuiButton-root {
        text-transform: none;
        font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-size: 1em;
        font-weight: 400;
    }

.TopTable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex: 1 0 auto;
    width: 100%;
    padding-bottom: 2em;
}

    .TopTable .MuiTableContainer-root {
        width: auto;
    }

    .TopTable .MuiTypography-h6 {
        padding-top: 1.5em;
        font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-size: 1.1rem;
    }


#approvalLegend {
    color: #17a2b8;
    height: 1.5em;
    width: 1.5em;
}

#inProgressLegend {
    color: #d8aa1f;
    height: 1.5em;
    width: 1.5em;
}

#errorLegend {
    color: #495057;
    height: 1.5em;
    width: 1.5em;
}

#LegendBody {
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 400;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    color: var(--text-primary-color,rgba(0, 0, 0, .9));
}

#Legend {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 10px;
}

#colorcode {
    margin-right: 2em;
    display: flex;
    flex-direction: row;
}

.commentBox {
    width: 50%;
    padding-top: 1em;
}
    .commentBox .MuiInputBase-root {
        font-size: 0.9em;
        font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    }

.RowFlex .commentBox .MuiFormLabel-root {
    color: rgba(25, 24, 24, 0.81);
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    line-height: 1;
    font-size: 1em;
    letter-spacing: 0.00938em;
}

.RowFlex .commentBox .MuiInputBase-input {
    padding: 1.5em 1em;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 1.0em;
}

.RowFlex .pkdd-buttonGroup .MuiButton-root:not(:disabled) {
    background: #2960db;
}

#refreshTopTable {
    background: #2960db;
    text-transform: none;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 1em;
    font-weight: 400;
    height: 2.8em;
}

.RowFlex .commentBox .MuiButton-root:not(:disabled) {
    background: #2960db;
}

table.pendingTable .MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover {
    background-color: rgba(128,128,128,.5);
    cursor: pointer;
}
    table.pendingTable .MuiTableRow-root.Mui-selected td.MuiTableCell-root MuiTableCell-body #PKPNTableBody {
        border: 1px solid rgba(128,128,128,.5);
    }

.refreshButtonTopTable{
    display:flex;
    flex-direction:column-reverse;
}

.RowFlex .MuiTablePagination-root{
    display:block;
}
#TenatProfileTableHeader {
    background-color: #efefef;
    text-transform: uppercase;
    text-align: left;
    font-weight: 635;
    border-bottom: 2px solid #3766c3;
    font-size: 1.0em;
}
#mtp-form-flex-root {
    width: 95%;
}

#mtp-form-flex-control {
    width: 100%;
    margin-bottom: 1em;
    padding: 0em 3em;
}

#mtp-form-flex {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    align-items: center;
}

#mtp-form-flex-root .MuiFormGroup-root {
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.mtp-form-label{
    margin:1.5em 0em;
}

.RowFlex .MuiInputBase-input {
    padding: 0.6em 1em;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 0.75em;
}

#mtp-tenant-cert-table {
    width: 100%;
    padding: 0em 3em;
}

#mtp-form-flex-root #mtp-tenant-cert-table .MuiFormGroup-root {
    flex-direction: column;
}

.mtp-table-margin {
    margin: 1.5em 0em;
}

.mtp-form-bottom-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 1em 0em;
    margin-bottom: 2em;
}

.RowFlex #mtp-form-flex-root .MuiButton-root {
    font-size: 1em;
    text-transform: none;
    letter-spacing: 0;
    padding: 0.4em 4em;
    min-width: 10em;
    font-weight: 600;
}

.RowFlex #mtp-form-flex-root .mtp-form-bottom-buttons .mtp-form-save .MuiButton-root {
    font-size: 1.1em;
    text-transform: none;
    letter-spacing: 0;
    background: #2960db;
    padding: 0.4em 4em;
    min-width: 10em;
    font-weight: 700;
}
.RowFlex #mtp-form-flex-root .mtp-form-bottom-buttons .mtp-form-cancel .MuiButton-root {
    font-size: 1.1em;
    text-transform: none;
    letter-spacing: 0;
    padding: 0.4em 4em;
    min-width: 10em;
    font-weight: 700;
}

.mtp-form-card-margin {
    margin-bottom: 1em;
}

.mtp-form-width-columns {
    width: 50%;
    margin-top: 1em;

}.mtp-form-width {
    width: 50%;
}

    .RowFlex .mtp-form-width-columns .MuiFormLabel-root {
        color: rgba(25, 24, 24, 0.81);
        padding: 0;
        font-size: 0.8em;
        font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
    }

    .RowFlex .mtp-form-width .MuiFormLabel-root {
        color: rgba(25, 24, 24, 0.81);
        padding: 0;
        font-size: 1em;
        font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-weight: 400;
        line-height: 1;
        letter-spacing: 0.00938em;
    }

    .RowFlex .MuiFormLabel-root {
        color: rgba(25, 24, 24, 0.81);
        padding: 0;
        font-size: 1.2rem;
        font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-weight: 500;
        line-height: 1;
        letter-spacing: 0.00938em;
    }

#TenantCertTableHeader {
    background-color: #efefef;
    text-transform: uppercase;
    text-align: center;
    font-weight: 635;
    border-bottom: 2px solid #3766c3;
    font-size: 0.95em;
    padding: 0.8em 0.9em;
}

#TenantCertTableBody {
    font-size: 0.9em;
    vertical-align: top;
    padding: 0.7em;
    max-width: calc(20vw);
    border: 1px solid #f3f3f3;
    word-break: break-word;
}

#mtp-form-card {
    border-radius: 1em;
}

#MTPTableBody {
    font-size: 0.9em;
    vertical-align: top;
    padding: 0.7em;
    border: 1px solid #f3f3f3;
    /*max-width: calc(20vw);*/
}

table.MTPTable tr.MTPTableBody:hover {
    background-color: rgba(128,128,128,.5);
    cursor: pointer;
}

#mtp-menu .MuiMenu-list .MuiMenuItem-root {
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 1em;
    color: #dee2e6;
}

#mtp-menu .MuiMenu-list {
    background: #72787d;
}

#mtp-menu .MuiListItem-button:hover {
    background: #4d565f;
}

.RowFlex .MuiFab-primary {
    background: #2960db;
    width: 10em;
}

.mtp-tenant-section {
    height: 48em;
    display: flex;
    flex-direction: column;
    width: 100%;
}

label {
    font-weight: 600;
}

#mtp-certificateTable {
    justify-content: center;
    max-height: 36em;
    width: 99.98%;
}

#mtp-radioButtons .MuiTypography-body1 {
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 1em;
}

.mtp-add-form .MuiFormControl-root .MuiInputBase-root {
    font-size: 1.3em;
}

.mtp-edit-form .MuiFormControl-root .MuiInputBase-root {
    font-size: 1.3em;
}
.fxs-panorama .fxs-home-target, .fxs-panorama .fxs-journey-target, .fxs-panorama .fxs-sidebar-flyout, .fxs-panorama .fxs-startboard-target {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    padding-left: 2em;
}

.fxs-portal-content {
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    position: relative;
    overflow: hidden;
    transition: margin .2s ease;
    z-index: 1;
}

.fxs-startboard {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: column nowrap;
    flex-flow: column nowrap;
    overflow: hidden;
}

.fxs-portal-main {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-align-items: stretch;
    align-items: stretch;
    height: calc(100% - 40px);
    position: relative;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 0;
}

.fxs-panorama .fxs-home-target {
    overflow-y: auto;
}

.fxs-sidebar {
    z-index: 3;
    position: relative;
    height: 100%;
    font-size: 13px;
    box-sizing: border-box;
}

#cardActionBar {
    flex: 0 0 auto;
    align-self: flex-start;
    margin-top: 8px;
    margin-right: -8px;
}

    #cardActionBar .popper {
        left: 56px;
        z-index: 999;
        overflow-x: visible;
    }
    #popper .MuiListItemText-root .MuiTypography-body1 {
        font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-size: 0.9em;
        color: black;
        font-weight: 300;
    }

#popper .MuiCardContent-root .MuiListItem-gutters {
    padding-left: 2px;
}

#popper .MuiCardContent-root .MuiListItemIcon-root {
    min-width: 3em;
}

.bolt-portal-host {
    position: fixed;
    z-index: 1000000;
}

.scroll-hidden {
    overflow: hidden;
}

.no-events {
    pointer-events: none;
}

.absolute-fill {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}

.bolt-tooltip-content {
    background-color: rgba(51,51,51,.95);
    font-size: 0.9em;
    background-color: rgba(var(--palette-neutral-80,51, 51, 51),.95);
    border-radius: 2px;
    color: rgba(255,255,255,1);
    color: rgba(var(--palette-neutral-0,255, 255, 255),1);
    padding: 8px 12px;
    text-align: left;
    word-break: break-all;
    word-break: break-word;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.fxs-panorama .bolt-tooltip {
    animation: fadein 300ms ease-in;
}


#popper {
    animation: fadein 30ms ease-in;
    z-index: 999;
    overflow-x: visible;
}

    #popper .MuiCardContent-root:last-child {
        padding-left: 1em;
        padding-bottom: 1em;
    }

.SubHeader {
    padding-bottom: 1.25em;
    font-size: 1.5em;
    font-weight: 400;
    padding-top: 1.25em;
    flex-shrink: 0;
    border-bottom: 1px solid;
    border-bottom-color: rgba(234,234,234,1);
    border-bottom-color: rgba(var(--palette-neutral-8,234, 234, 234),1);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: 1.5em;
}

.SubHeaderName {
    padding: 0 8px;
    display: flex;
    font-weight: 350;
    align-items: center;
}

#cardActionBar .SubActionBottomBorder {
    border-bottom: 1px solid;
    border-bottom-color: #f8f9fa;
    padding-bottom: 0.6em;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    color: black;
    font-weight: 450;
    font-size: 1.13em;
}

.SubHeaderWithActionButton {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#ActionButton {
    text-transform: none;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 0.65em;
    font-weight: 400;
    height: 3em;
}
a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}
.project-navigation {
    background: rgba(0,0,0,.08);
    background: var(--nav-vertical-background-color,rgba(0, 0, 0, .08)); 
    border-color: rgba(234,234,234,1);
    border-color: rgba(var(--palette-neutral-8,234, 234, 234),1);
    border-right: 1px solid rgba(0,0,0,.1);
    border-right: 1px solid var(--palette-black-alpha-10,rgba(0, 0, 0, .1));
    color: rgba(0,0,0,.9);
    color: var(--text-primary-color,rgba(0, 0, 0, .9));
    white-space: nowrap;
}

    .project-navigation .hub-group-container.expanded-container {
        background-color: dimgrey;
        position: relative;
    }

.region-navigation {
    overflow: hidden;
    position: relative;
    transition: width 1ms cubic-bezier(.4,0,.2,1);
}
.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}
.hub-group-container{

}
.displayed-container{

}
.project-navigation  .displayed::before {
    background-color: rgba(0,120,212,1);
    background-color: var(--communication-background,rgba(0, 120, 212, 1));
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: 3px;
}
.project-navigation .displayed-container::before {
    bottom: 0;
    background-color: #dee2e6;
    content: "";
    left: 0;
    position: absolute;
    top: 0;
    width: 3px;
}
.bolt-link {
    border-radius: 2px;
    color: rgba(0,90,158,1);
    color: var(--communication-foreground,rgba(0, 90, 158, 1));
    cursor: pointer;
    outline: transparent;
    text-decoration: none;
    transition: color 80ms cubic-bezier(.165,.84,.44,1),background 80ms linear;
}

.navigation-container {
}

.project-navigation .MuiListItem-gutters {
    padding-left: 8px;
    padding-right: 6px;
}


#MainActions .hub-group-container .MuiListItemText-root .MuiTypography-body1 {
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 0.9em;
    color: #dee2e6;
    font-weight: 400;
}

#MainActions .MuiListItemText-root .MuiTypography-body1 {
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 0.9em;
    color: #dee2e6;
    font-weight: 400;
}

#MainActions #subMenu .MuiListItemText-root .MuiTypography-body1 {
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 0.9em;
    color: #dee2e6;
    font-weight: 300;
}

#MainActions #subMenu #selectedSubAction .MuiListItemText-root .MuiTypography-body1 {
    font-weight: 500;
}

.navigation-container .makeStyles-toolbar-10{
    min-height:0;
}

.arrowIcon:hover {
    color: rgba(0,90,158,1);
}



#popper .MuiListItemText-root .MuiTypography-body1 {
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 0.9em;
    color: #dee2e6;
    font-weight: 300;
}

    #popper .MuiCardContent-root .MuiListItem-gutters {
        padding-left: 2px;
    }

    #popper .MuiCardContent-root .MuiListItemIcon-root {
        min-width: 3em;
    }

.icon .mi {
    font-family: 'SegoeMDL2';
    font-size: 40px;
    display: inline-block;
    height: 40px;
    color:white;
}

#MainActions .MuiListItemIcon-root {
    color: #dee2e6;
}
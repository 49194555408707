.kct-rowflex-section {
    height: 48em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.kct-toolUserGuide-button{
    width:15%;
}

.RowFlex .kct-rowflex-section .kct-toolUserGuide-button .MuiButton-root {
    font-size: 1em;
    text-transform: none;
    letter-spacing: 0;
    padding: 0.4em 4em;
    min-width: 10em;
    font-weight: 600;
}

.kct-tablerow-flex {
    display: flex;
    align-items: center;
}

.kct-bottom-info {
    text-align: justify;
    text-justify: inter-word;
}

.RowFlex .kct-rowflex-section .MuiButton-root {
    font-size: 1.1em;
    text-transform: none;
    letter-spacing: 0;
    padding: 0.4em 1.4em;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 300;
}
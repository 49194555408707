#RSMCertTableBody {
    font-size: 0.9em;
    vertical-align: top;
    padding: 0.7em;
    max-width: calc(20vw);
    border: 1px solid #f3f3f3;
    word-break: break-word;
}

#RSMOrgCertTableHeader {
    background-color: #efefef;
    text-transform: uppercase;
    text-align: center;
    font-weight: 635;
    border-bottom: 2px solid #3766c3;
    font-size: 0.95em;
    padding: 0.8em 0.9em;
}

.rsmcert-top-button-text-div {
    margin: 0em 1em;
    display: flex;
    align-items: center;
}

#rsmcert-top-button{
    margin-bottom:1em;
    align-self: flex-end;
}
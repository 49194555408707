.RowFlex-submitRequest {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: 2em;
    margin-top: 2em;
    width: 95%;
}

    .RowFlex-submitRequest .MuiInputBase-root {
        font-size: 1.4em;
        font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    }

#CardProps {
    margin: 0 auto;
    width: 50%;
    float: none;
    margin-top: 1em;
}

.FlexLabel {
    display: flex;
    flex: 1 0 auto;
    justify-content: space-between;
    align-items: center;
}

.RowFlex-submitRequest .MuiInputLabel-outlined {
    font-size: 1em;
}

#HFDTableBody .MuiButton-root {
    font-size: 1.1em;
    text-transform: none;
    letter-spacing: 0;
    padding: 0.4em 1.4em;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 300;
}

.submitBottomButtons {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
}

.msportalfx-shadow-level4 {
    box-shadow: 0 0.7em 1.4em 0 rgba(0,0,0,.16);
}

.fxs-mode-light .msportalfx-shadow-level4 {
    box-shadow: 0 0.7em 1.4em 0 rgba(0,0,0,.16);
}

.fxs-topbar {
    height: 4em;
    position: relative;
    z-index: 5;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 1em;
}

.fxs-theme-light .fxs-topbar {
    background-color: #043771;
}

.fxs-topbar {
    background-color: #3b3d48;
    color: #fff;
    width: 100%;
}

.fxs-topbar-content {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    -webkit-app-region: drag;
    display: -webkit-flex;
    display: flex;
    -webkit-align-content: center;
    align-content: center;
    -webkit-align-items: center;
    align-items: center;
    justify-content: space-between;
}

.fxs-topbar-brand, .fxs-topbar-preview {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    margin: 0;
    padding: 0;
    height: 100%;
}

.fxs-topbar-home {
    color: #fff;
    text-decoration: none;
}

[type=button], [type=reset], [type=submit], button {
    font-size: 1em;
    line-height: 1.385;
    background: 0 0;
    color: inherit;
}

.fxs-topbar-brand .fxs-topbar-home, .fxs-topbar-preview .fxs-topbar-internal {
    display: inline-block;
    text-decoration: none;
    font-size: 1.5em;
    padding: 0 1.4em;
    height: 40px;
    line-height: 4em;
    font-weight: 600;
    height: 2.3em;
    position: relative;
    display: flex;
    align-items: center;
}

.fxs-dropmenu {
    position: relative;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
    z-index: 200;
}

.fxs-topbar-avatarmenu .fxs-dropmenu-button, .fxs-topbar-button {
    fill: #fff;
}

.fxs-dropmenu-button {
    border: none;
    padding: 0;
    margin: 0;
    background: 0 0;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    position: relative;
    height: 100%;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}

.fxs-avatarmenu-header {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    height: 40px;
    padding-right: 1em;
}

.fxs-avatarmenu-username {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    line-height: normal;
    max-width: 160px;
}

.fxs-avatarmenu-tenant {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
    font-size: 10px;
    line-height: normal;
    text-transform: uppercase;
    max-width: 160px;
}

.fxs-avatarmenu-tenant-image-container {
    height: 3em;
    width: 3em;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-left: 6px;
    padding-right: 6px;
    box-sizing: content-box;
}

.fxs-search-box, .fxs-topbar-avatarmenu, .fxs-topbar-button, .fxs-topbar-reportbug {
    -webkit-app-region: no-drag;
}

.fxs-avatarmenu {
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;
    box-sizing: border-box;
}

button, input, select, textarea {
    font-family: az_ea_font,'Segoe UI',az_font,system-ui,-apple-system,BlinkMacSystemFont,Roboto,Oxygen,Ubuntu,Cantarell,'Open Sans','Helvetica Neue',sans-serif;
    font-weight: 400;
    border-radius: 0;
}

.fxs-avatarmenu-tenant-container {
    text-align: right;
    -webkit-flex: 1 1 auto;
    flex: 1 1 auto;
    padding-left: 20px;
}

.fxs-avatarmenu-tenant-image {
    height: 3em;
    width: 3em;
    border: 0;
    border-radius: 28px;
}

.mectrl_currentAccount {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: auto 1fr auto;
    grid-template-columns: auto 1fr auto;
    -ms-grid-rows: 48px auto;
    grid-template-rows: 48px auto;
    background-color: lightslategrey;
}

    .mectrl_currentAccount .mectrl_accountInfo {
        min-height: 132px;
        width: 100%;
        -ms-grid-row: 2;
        -ms-grid-row-span: 1;
        grid-row: 1/3;
        -ms-grid-column: 1;
        -ms-grid-column-span: 3;
        grid-column: 1/4;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
    }

#mectrl_currentAccount_picture {
    display: block;
    border-radius: 50%;
    overflow: hidden;
    width: 4em;
    height: 4em;
    margin-left: 16px;
    margin-right: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
}

.mectrl_profilepic {
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
}

.mectrl_currentAccount .mectrl_accountInfo .mectrl_accountDetails {
    font-size: 1em;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    min-width: 0;
    width: 0;
    padding-right: 1em;
}

.mectrl_currentAccount .mectrl_accountInfo .mectrl_name {
    font-size: 1.413em;
    font-weight: 700;
}

.mectrl_currentAccount .mectrl_accountInfo .mectrl_accountDetails > :first-child {
    margin-top: 0;
}

.mectrl_currentAccount .mectrl_accountInfo .mectrl_accountDetails > :not(:nth-child(2)) {
    margin-top: 4px;
}

.mectrl_currentAccount .mectrl_accountInfo .mectrl_accountDetails > :nth-child(2) {
    margin-top: 3px;
    font-size: 13px;
}

.mectrl_currentAccount .mectrl_accountInfo .mectrl_accountDetails > * {
    padding: 1px;
}

.mectrl_currentAccount .mectrl_accountInfo .mectrl_accountDetails .mectrl_link {
    color: #0078d6;
    text-decoration: none;
}

.mectrl_resetStyle, a.mectrl_resetStyle, button.mectrl_resetStyle {
    border-style: none;
    border-width: 0;
    padding: 0;
    margin: 0;
    outline-style: none !important;
    background-color: transparent;
    text-decoration: none;
    text-align: left;
    font-family: "Segoe UI","Segoe UI Web Regular","Segoe UI Symbol","Helvetica Neue","BBAlpha Sans","S60 Sans",Arial,sans-serif;
    cursor: pointer;
}

.dp-rowflex-section {
    height: 48em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}

.dp-icon {
    height: 1.8em;
}

.RowFlex .dp-rowflex-section .MuiButton-root {
    font-size: 1.1em;
    text-transform: none;
    letter-spacing: 0;
    padding: 0.4em 1.4em;
    font-family: "Segoe UI VSS (Regular)", "Segoe UI", "-apple-system", BlinkMacSystemFont, Roboto, "Helvetica Neue", Helvetica, Ubuntu, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    font-weight: 300;
}

.hfd-RowFlex {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: space-around;
    align-items: flex-start;
    margin-left: 2em;
    margin-top: 2em;
    width: 95%;
}

#TableHeader {
    background-color: #efefef;
    text-transform: uppercase;
    text-align: left;
    font-weight: 635;
    border-bottom: 2px solid #3766c3;
    font-size: 1.0em;
}

#HFDTableBody {
    font-size: 0.9em;
    justify-content: center;
    padding: 0.4em 1em;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.hfd-icon {
    margin-right: 0.5em;
}

.hfd-download-icon {
    margin-left: 0.9em;
}

.hfd-top-navigation{
    display:flex;
    margin-bottom: 1.8em;
}

.hfd-top-bar {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.hfd-RowFlex .hfd-top-bar .MuiButton-root {
    font-size: 0.9em;
    padding: 0.5em;
    margin-bottom: 2em;
}

.hfd-location-tab {
    font-weight: 700;
    margin-right: 0.7em;
}

.hfd-top-link {
    color: #0056b3;
}

    .hfd-top-link:hover {
        text-decoration: underline;
    }

.hfd-RowFlex .MuiButton-root {
    font-size: 1.1em;
    text-transform: none;
    letter-spacing: 0;
    padding: 0.4em 1.4em;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-weight: 300;
}

table.hashTable td.HFDTableBody:hover {
    background-color: rgba(128,128,128,.5);
    cursor: pointer;
}

.hfd-bottom-info {
    text-align: justify;
    text-justify: inter-word;
}

.hfd-rowflex-section {
    height: 48em;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}


.hfd-RowFlex .MuiTablePagination-root {
    display: block;
}

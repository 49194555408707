.StartContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1 0 auto;
    width: 100%;
    padding-bottom: 2em;
}

.SplitButton {
    z-index: 999;
    padding-left: 4em;
}

.TopButtonRow {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    width: 100%;
    align-items: baseline;
    padding-bottom:4em;
}
    .TopButtonRow .MuiFormControl-marginNormal{
        margin-top:0em;
        margin-bottom:0em;
    }
    .TopButtonRow .MuiInputBase-root {
        font-size: 1.1em;
    }
    .TopButtonRow .MuiMenuItem-root {
        font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
        font-size: 0.9em;
        color: #dee2e6;
        font-weight: 400;
    }
    .TopButtonRow .MuiListItem-root.Mui-selected, .MuiListItem-root.Mui-selected:hover {
        background-color: dimgrey;
    }

#TableHeaderMain {
    background-color: #efefef;
    text-transform: uppercase;
    text-align: center;
    font-weight: 635;
    border-bottom: 2px solid #3766c3;
    font-size: 0.9em;
}
.selectedRowExpanded {
    background: dimgrey;
}
#TableHeaderRequest {
    background: #323130;
    text-transform: uppercase;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    font-weight: 450;
    border-bottom: 2px solid #3766c3;
    font-size: 0.9em;
    color: #dee2e6;
}
    #TableHeaderRequest .MuiTableSortLabel-root.MuiTableSortLabel-active {
        color: #dee2e6;
    }
        #TableHeaderRequest .MuiTableSortLabel-root.MuiTableSortLabel-active.MuiTableSortLabel-root.MuiTableSortLabel-active .MuiTableSortLabel-icon{
            color:#dee2e6;
        }

#PKPNTableBodyMain {
    font-size: 1em;
    vertical-align: top;
    max-width: calc(20vw);
    border: 0.6px solid #6c757d;
    word-break: break-word;
}

#PKPNTableBodyRequest {
    font-size: 1em;
    vertical-align: top;
    padding-bottom: 0px;
    padding-left: 10px;
    padding-top: 5px;
    max-width: calc(20vw);
    border: 1px solid #f3f3f3;
    background: #f8f9fa;
}

.DatePicker {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
}

#requestTableFooter {
    background: #323130;
    color: #dee2e6;
}

    #requestTableFooter .MuiTablePagination-root {
        color: #dee2e6;
    }
    #requestTableFooter .MuiIconButton-root{
        color: #dee2e6;
    }
        #requestTableFooter .MuiIconButton-root.Mui-disabled {
            color: #8d8f90;
        }
.requestTableFooter .MuiSelect-icon {
    color: #dee2e6;
}

.TopButtonRow .MuiFormControl-fullWidth {
    width: 50%;
}

#pkpnTable .checkboxCell {
    background: #3b3d48;
}
.checkboxCell .MuiSvgIcon-root{
    color: #dee2e6;
}

.ColumnFlex{
    display:flex;
    flex-direction: column;
    max-height:43em;
}
    .ColumnFlex .MuiButton-root {
        width: 15%;
        margin-top: 2em;
        align-self: flex-end;
    }

.RowFlex .SplitButton .MuiButton-root {
    text-transform: none;
    background: #2960db;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

.RowFlex .ColumnFlex .MuiButton-root {
    text-transform: none;
    padding: 0.6em 0em;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
}

    .RowFlex .ColumnFlex .MuiButton-root:not(:disabled) {
        background: #2960db;
    }

.RowFlex .StartContent .MuiFormLabel-root {
    color: rgba(25, 24, 24, 0.81);
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    line-height: 1;
    font-size: 1em;
    letter-spacing: 0.00938em;
}

.RowFlex .StartContent .MuiInputBase-input {
    padding-right: 24px;
    font-family: "Segoe UI VSS (Regular)","Segoe UI","-apple-system",BlinkMacSystemFont,Roboto,"Helvetica Neue",Helvetica,Ubuntu,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    font-size: 0.9em;
}

.RowFlex .StartContent .ColumnFlex .MuiSelect-select:not([multiple]) option, .MuiSelect-select:not([multiple]) optgroup {
    color: black;
}
.RowFlex .StartContent #requestTableFooter .MuiSelect-icon {
    color: white;
}